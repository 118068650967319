body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.login-background {
  background-size: contain !important;
  background-repeat: repeat-x !important;
}

button {
  padding: 8px 16px !important;
}

.web-user-button {
  color: white !important;
  background-color: #ff4e74 !important;
  margin-left: 10px !important;
}

.web-validate-button {
  color: white !important;
  background-color: #3f51b5 !important;
  margin-left: 10px !important;
}

.web-invoice-button {
  color: white !important;
  background-color: #3f51b5 !important;
}

.web-disabled-button {
  color: white !important;
  background-color: #727376 !important;
}

.web-user-button:hover {
  background-color: #ff3155 !important;
}

.plan-user-button {
  color: white !important;
  background-color: #986644 !important;
  margin-left: 10px !important;
}

.plan-user-button:hover {
  background-color: #9b4912 !important;
}

.send-user-button {
  color: white !important;
  background-color: #5d1e9c !important;
  margin-left: 10px !important;
}

.send-user-button:hover {
  background-color: #441672 !important;
}

.set-coupon-reseller-button {
  color: white !important;
  background-color: #bc3967 !important;
  margin-left: 10px !important;
}

.set-coupon-reseller-button:hover {
  background-color: #c1557b !important;
}
